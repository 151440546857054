import { combineReducers } from 'redux'
import { DESTROY_SESSION } from '../actions/actionTypes';
import app from './app';
import dashboard from './dashboard';
import files from './files';
import table from './table';
import peers from './peers';

const appReducer = combineReducers({
  app,
  dashboard,
  files,
  table,
  peers
});

const rootReducer = (state, action) => {   
  // Clear all data in redux store to initial.
  if (action.type === DESTROY_SESSION)
     state = undefined;
  
  return appReducer(state, action);
};

export default rootReducer;
