// @flow

import React from 'react';

import './styles.scss';

const Settings = () => (
  <h2 className='settings__text'>Hello, this is Settings!</h2>
)

export default Settings;