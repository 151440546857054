const catchUnknownError = (error, dispatch) => {
  let errorInfo = new Error(error);

  if (errorInfo) {
    // do something here
  }
}

const catchRequestError = (data, dispatch) => {
  if (data?.response) {
    if (data.response?.status === 401) {
      // do something here
    } else {
      // do something here
    }
  } else {
    catchUnknownError(data, dispatch);
  }
}

export default catchRequestError;