// @flow

import React from 'react';

import './styles.scss';

type Props = {
    name: String,
    id: String,
    label: String,
    additionalClassName: String,
    checked: Boolean,
    disabled: Boolean,
    onClick: Function,
};

const Checkbox = ({
    name = 'checkbox',
    id = '',
    label = '',
    additionalClassName = '',
    checked = false,
    disabled = false,
    onChange = () => {},
}: Props) => (
    <label className={`${additionalClassName} checkbox-label`}>
      <input
        type="checkbox"
        name={name}
        id={id}
        checked={checked}
        onChange={() => onChange(id)}
        disabled={disabled}
      />
      <span className={`checkbox-custom rectangular`}></span>
    </label>
);

export default Checkbox;