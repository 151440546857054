//flow

import React from 'react';
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer,Cell } from 'recharts';

import './styles.scss';


const TotalWidget = ({
  data,
}) => (
  <div className='pie_widget__wrapper'>
    <div title={data?.title || ''} className='pie_widget__title'>{data?.title || ''}</div>
    <div className="data-container">
      <div className="pie-container">
        <div className="pie">
          <ResponsiveContainer >
            <PieChart >
              <Pie
                data={[{ value: 100 }]}
                paddingAngle={0}
                stroke=''
                dataKey="value"
                innerRadius={83}
                outerRadius={111}
                fill="#EEEFF2"
                stroke=''
                isAnimationActive={false} />
              <Pie
                dataKey="value"
                isAnimationActive={false}
              //  data={[{ value: 70 },{value:30}]}
                data={data?.pieData?.pieChart}
                paddingAngle={0}
                startAngle={90}
                endAngle={-270}
                innerRadius={91}
                outerRadius={103}
                cornerRadius={6}
                fill="#EEEFF2"
                stroke=''
              >
                <Cell fill={data?.pieData?.color}/>
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          
        </div>
        {data?.addInfo ? <div className="add-info">
          {data?.addInfo}
        </div> : null}
        <div className="pie-circle">
        <div className="pie-circle-1">{data?.pieData?.field_1}</div>
          <div className="pie-circle-2">{data?.pieData?.field_2}</div>
        </div>

      </div>
      <div className="separator"></div>
      <div className="text-container">
        <table className="data-table">
          <tbody>
            {data.text.map((item, index) => (
              <tr key={index}>
                <td className="colName">
                  {item.label}
                </td>
                <td className="colValue">{item.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>

  </div>
);

export default TotalWidget;
