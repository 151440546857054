// @flow

import React, { useState } from 'react';

import './styles.scss';

type Props = {
  placeholder: string,
  onChange: (value: string) => void,
};

const WalletHost = ({
  placeholder = '',
  onChange = () => {},
  data,
}: Props) => {
  const [inputValue, setInputValue] = useState('');
  
  return (
    <div className='wallet-host'>
      <div className="title">{data?.title}</div>
      <div className="content">{data?.id}</div>
    </div>
  )
};

export default WalletHost;