import * as types from './actionTypes';
import * as api from '../../api';
import catchRequestError from '../../commonExtensions/catchRequestError';
import { Axiosinstance } from '../../api';

export const setPeersList = payload => ({
  type: types.SET_PEERS_LIST,
  payload
});

export function getPeersList() {
	return dispatch => {
		return (
			Axiosinstance.get(api.ui + `/sellers/workers`).then(function (response) {
				dispatch(setPeersList(response.data));
			})
      .catch((error) => {
        catchRequestError(error, dispatch);
      })
		)
	}
}