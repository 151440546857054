import React from 'react';

import './styles.scss';

type Props = {
  data: {
    type: string,
    caption: string,
  }
}

const getIcon = (type) => {
  if (type === 'download') return 'action_btn__wrapper--download';

  return '';
}

const ActionButton = ({
  data,
  onClick,
}:Props) => (
  <button
    className={`action_btn__wrapper ${getIcon(data?.type)}`}
    onClick={() => onClick()}
  >
    {data?.caption || ''}
  </button>
);

export default ActionButton;