// @flow

import React, {
  useState,
  useEffect,
} from 'react';
import isEqual from 'lodash.isequal';
import StatusIcon from '../StatusIcon';
import HeaderBtn from '../HeaderBtn';
import sortBy from 'lodash.sortby';
import { useNavigate, Link } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid'
import { useComponentVisible } from '../../commonExtensions/hooks';
import {
  getFileInfo,
} from '../../store/actions';

import locale from '../../locale';

import {
  useSelector,
  useDispatch,
} from 'react-redux';


import './styles.scss';

type Props = {
  data: any,
};

const GetListItemValue = ({ column, value,file_id },setIsComponentVisible,dispatch) => {


  const linkTarget = {
    pathname: `/filesDetails/${file_id}`,
    key: uuidv4(), // we could use Math.random, but that's not guaranteed unique.
    state: {
      applied: true
    }
  };
  
  if (column === 'getState') return (
    <div
      key={column}
      className='header_popup__content_list_item_icon'
    >
      <StatusIcon data={value} />
    </div>);

  return (
    
    <p
      key={column}
      title={value || ''}
      className='header_popup__content_list_item_value'
    >{column === 'name' ?
     <Link onClick={()=> {setIsComponentVisible(false);dispatch(getFileInfo(file_id))}} to={linkTarget} >
    {value || ''} </Link> : (value || '')  }
    </p>);
}

const GetListItem = (data,setIsComponentVisible,dispatch) => {
  if (data?.body && data?.header) {

    let sortedObjs = sortBy( data.body, 'progress' );
  

    return sortedObjs.map((bodyItem) => (
      <div
        key={bodyItem[data?.keyId] || bodyItem.id || ''}
        className={`header_popup__content_list_item header_popup__content_list_item--${data?.type}`}
      >
        {data.header.map(headerItem => GetListItemValue({
          column: headerItem.column,
          value: bodyItem[headerItem.column],
          file_id:bodyItem.id
        },setIsComponentVisible,dispatch))}
      </div>
    ));
  }

  return null;
}

const HeaderPopup = ({
  data,
}: Props) => {
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);

  const [widgetData, setWidgetData] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEqual(data, widgetData)) return;
    setWidgetData(data);
  }, [data]);

  const onBtnClick = () => {
    setIsComponentVisible(!isComponentVisible);
  }

  return (
    <div className='header_popup__wrapper'>
      <HeaderBtn
        data={{
          type: widgetData?.type,
          counter:widgetData?.counter ? widgetData.counter : 0,
        }}
        type={widgetData?.type}
        onClick={onBtnClick}
      />
      {isComponentVisible &&
        <div
          ref={ref}
          className={`header_popup__container ${widgetData?.body?.length === 0 ? 'header_popup__container--empty' : ''}`}
          style={data?.popupStyles || null}
        >
          {widgetData?.body?.length === 0 &&
            widgetData?.emptyMsg &&
              <p className='header_popup__content_empty_text'>{widgetData?.emptyMsg}</p>}
          {widgetData?.body?.length > 0 &&
            <div className='header_popup__content_list'>
              {GetListItem(widgetData,setIsComponentVisible,dispatch)}
            </div>}
        </div>}
    </div>
  );
};

export default HeaderPopup;
