// @flow

import React, { useState } from 'react';

import './styles.scss';

type Props = {
  name: String,
  type: String,
  value: String | Number,
  placeholder: String,
  showPassBtn: Boolean,
  warning: Boolean,
  onChange: Function,
};

const Input = ({
  name = 'input',
  type = 'text',
  placeholder = '',
  value = '',
  showPassBtn = false,
  warning = false,
  onChange = () => {},
}: Props) => {
  const [passVisible, setPassVisibility] = useState(false);

  return (
    <div className={`input__container_wrapper`}>
      <input
        name={name}
        type={showPassBtn && passVisible ? 'text' : type}
        placeholder={placeholder}
        value={value}
        className={`input__wrapper ${warning ? 'input__wrapper--warning' : ''} ${showPassBtn ? 'input__pass_wrapper' : ''} ${passVisible ? '' : 'input__pass_text_spacing'}`}
        onChange={(e) => onChange(e)}
      />
      {showPassBtn
        &&
        <button
          className={`input__eye_btn ${passVisible ? 'input__eye_btn_open' : 'input__eye_btn_close'}`}
          onClick={() => setPassVisibility(!passVisible)}
        />
      }
    </div>
  )
};

export default Input;