// flow

import React from 'react';
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { stringToLowerCase } from '../../commonExtensions/common';
import Loader from '../../components/Loader';
import { setTableSelectedRowData } from '../../store/actions';

import './styles.scss';

type Props = {
  data: any,
};

const convertDownloadState = (progress) => {
  if (progress == 0) {
    return 'Cloud'
  }
  if (progress == 100) {
    return 'Local & Cloud'
  }
  return <div className="wrap-loader"><Loader/><div>{progress}% Downloaded...</div> </div>;
}

const converState = (state) => {
 
    return <span className={state.toLowerCase() === 'online' ? 'bold-green' : 'bold-red'}>{state}</span>
  
 }

const getColumnValue = ({ bodyItem, column, config }) => {
  if (config?.type === 'files') {
    const valueToRender = stringToLowerCase(column) === 'offlinestate' ? convertDownloadState(bodyItem[column]) : (bodyItem?.[column] || '');

    if (config?.selectable) {
      return (<Link to={{ pathname:`/filesDetails/${bodyItem?.id}` }}>{valueToRender}</Link>)
    }

    return valueToRender;
  }

  if (config?.type === 'peers') {
    const valueToRender = stringToLowerCase(column) === 'state' ? converState(bodyItem[column]) : (bodyItem?.[column] || '');

    return valueToRender;
  }

  return bodyItem?.[column] || '';
}

const Table = ({
  data,
}: Props) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const onTableRowClick = ({ e, selectedItem }) => {
    if (data?.config?.type === 'files' && data?.config?.selectable) {
      dispatch(setTableSelectedRowData(selectedItem));
    } else {
      e.preventDefault();
    }
  }

  const getAlign = (column) => {
    if (column === 'size') return 'table__value_align_right';
   

    return '';
  }

  return (
    <table className='table__wrapper'>
      <thead className='table__thead_wrapper'>
        <tr className='table__thead_row_wrapper'>
          {data?.header && data.header.map(headerItem => (
            <th
              key={headerItem.column || ''}
              title={headerItem.title || ''}
              style={headerItem.style || null}
              className={`table__th_wrapper ${getAlign(headerItem.sort || headerItem.column)}`}
            >
              {headerItem.title || ''}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.body && data.body.map(bodyItem => (
          <tr
            key={bodyItem?.[data?.keyId] || bodyItem?.id || ''}
            className={`table__body_row_wrapper ${data?.config?.selectable ? 'table__body_row_wrapper--selectable' : ''}`}
            onClick={(e) => onTableRowClick({
              e,
              selectedItem: bodyItem,
            })}
          >
            {data?.header && data.header.map(headerItem => (
                <td
                  key={headerItem.column || ''}
                  title={bodyItem[headerItem.column] || ''}
                  className={`table__td_wrapper ${getAlign(headerItem.sort || headerItem.column)}`}
                >
                  {getColumnValue({
                    bodyItem,
                    config: data?.config,
                    column: headerItem.column
                  })}
                </td>
              ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
};

export default Table;