import Dashboard from './containers/Dashboard';
import Peers from './containers/Peers';
import Files from './containers/Files';
import Billing from './containers/Billing';
import Settings from './containers/Settings';
import FilesDetails from './containers/FilesDetails';

const routes = [
  {
    path: '/',
    element: Dashboard,
    sidebar: true,
  },
  {
    path: '/dashboard',
    element: Dashboard,
    sidebar: true,
  },
  {
    path: '/peers',
    element: Peers,
    sidebar: true,
  },
  // {
  //   path: '/files',
  //   element: Files,
  //   sidebar: true,
  // },
  {
    path: '/filesDetails/:id',
    element: FilesDetails,
    exact:true,
  },
  {
    path: '/billing',
    element: Billing,
    sidebar: true,
  },
  {
    path: '/settings',
    element: Settings,
    sidebar: true,
  }
];

export default routes;