// @flow

import React, { useState,useEffect } from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import WalletHost from '../WalletHost';
import HeaderPopup from '../HeaderPopup';
import {
  getFiles,
} from '../../store/actions';

import {
  getFilesPopupWidgetData,
} from '../../store/selectors';
import SwitcherToSellerUi from '../SwitcherToSellerUi'

import locale from '../../locale';

import './styles.scss';

type Props = {

};

const Header = ({
  logout
}: Props) => {
  const dispatch = useDispatch(); 
  const filesPopupWidgetData = useSelector(getFilesPopupWidgetData);

  const [userName, setUserName] = useState(localStorage.getItem('userName'));

  // useEffect(() => {
  // //  dispatch(getFiles());
  //  // let getFilesIntervalRequest = setInterval(() => dispatch(getFiles()), 5000);

  //   return () => clearInterval(getFilesIntervalRequest);
  // }, []);

  const notificationsData = {
    type: 'notifications',
    emptyMsg: locale('IDS_NOTIFICATIONS_POPUP_WIDGET_EMPTY_MSG'),
    popupStyles: {
      width: `${311 / 16}rem`,
    },
    keyId: 'id',
    header: [
      { column: 'message' },
    ],
    body: [
      {
        id: '1',
        message: 'some message',
      },
    ],
    counter:2,
  };

  const hostIdData = {
    title:'Host ID:',
    id:'00pPKHjWprnV...EsAoXX2YQvU'
  }
  const walletData = {
    title:'Wallet address:',
    id:'00pPKHjWprnV...EsAoXX2YQvU'
  }


  const logoutClick = () => {
    logout();
  }



  return (
    <div className='header__wrapper'>
      <div className='host-id__wallet-adress__container'>
        <WalletHost data={hostIdData} />
        <WalletHost data={walletData} />
        
      </div>
      <SwitcherToSellerUi/>
      <div className='header__manage_wrapper'>
        <div className='header__manage_btn'>
          <HeaderPopup data={filesPopupWidgetData} />
        </div>
        <div className='header__manage_btn header__manage_btn--mr32'>
          <HeaderPopup data={notificationsData} />
        </div>
        <div className='header__manage_selectbox'>
          <div className='header__manage_selectbox_btn'>
            <div className='userName'>
            {userName}
            </div>
           
          </div>
        </div>
        <button onClick={logoutClick} className='header__logout_btn'>
            Logout
          </button>
      </div>
    </div>
  )
}

export default Header;