import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from '../../components/Table';

import { getPeersList } from '../../store/actions';
import { getPeersListTableValue } from '../../store/selectors';

import './styles.scss';

const Peers = () => {
  const dispatch = useDispatch();
  const tableData = useSelector(getPeersListTableValue);

  useEffect(() => {
    dispatch(getPeersList());

    let getPeersListIntervalRequest = setInterval(() => dispatch(getPeersList()), 30000);

    return () => clearInterval(getPeersListIntervalRequest); 
  }, []);

  return (
    <div className='peers__wrapper'>
      <div className='peers__table'>
        <Table data={tableData} />
      </div>
    </div>
  )
}

export default Peers;