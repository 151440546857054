import { createSelector } from 'reselect';
import { reducer } from '../../commonExtensions/common';
import { convertBytes } from '../../commonExtensions/bytesParser';


const getDashCounterValue = state => state.dashboard.value;
const widgetData = state => state.dashboard.widget_info;

const getDashList = (state) => {
  console.log('check for updates')
  return state.dashboard.dashItems 
};

export const getCouterValue = createSelector(getDashCounterValue, (value) => {
  return value;
});


export const getStatusValue = createSelector(widgetData, (value) => {
  let widget = value.status;
 // widget = widget?.data
  return widget;
});



export const getUsedSpaceValue = createSelector(widgetData, (value) => {
  let widget = value.usedSpace;
//  widget = widget?.data
  
  const model = {
    title: 'Used Space',
    field: (Math.round(((widget?.usedSpaceBytes) / (widget?.totalSpaceBytes)) * 100)) || 0 + '%',
    field_2:`+${widget?.usedSpaceWeeklyChange || 0}% this week`,
    field_3:`Total space: ${convertBytes(widget?.usedSpaceBytes)}/${convertBytes(widget?.totalSpaceBytes)}`
  }

  return model;




});


export const getTrustScore = createSelector(widgetData, (value) => {

  let widget = value.trustScore;
 // widget = widget?.data

  console.log(widget?.uptimeScoreCeiling)

  const model = {
    title:'Trust score',
    pieData:{
      pieChart:[{value:widget?.totalScore},{value:100 - widget?.totalScore}],
      field_1:widget?.totalScore || 0,
      field_2:`+${widget?.totalScoreWeeklyChange || 0} this week`,
      color:'#24B3DF'
    },
    text:[ {label:'Uptime:',value:`${widget?.uptimeScore || 0}/${widget?.uptimeScoreCeiling || 0}`},
           {label:'Age:',value:`${widget?.ageScore || 0}/${widget?.ageScoreCeiling || 0}`},
           {label:'Software version:',value:`${widget?.softwareVersionScore || 0}/${widget?.softwareVersionScoreCeiling || 0}`},
           {label:'Upload speed:',value:`${widget?.uploadSpeedScore || 0}/${widget?.uploadSpeedScoreCeiling || 0}`},
           {label:'Download speed:',value:`${widget?.downloadSpeedScore || 0}/${widget?.downloadSpeedScoreCeiling || 0}`},
          ],
    addInfo:''
  }


  return model;
});

export const getPayout = createSelector(widgetData, (value) => {


  const model = {
    title:'Payout',
    pieData:{
      pieChart:[{value:79},{value:31}],
      field_1:'67',
      field_2:'~ 25.2 SWC',
      color:'#4ED678'
    },
    text:[ {label:'Storage served:',value:'147.2 GB'},
           {label:'Tasks completed:',value:'927'},
           {label:'Storage rent',value:'234'},
           {label:'Consistency checks',value:'222'},
           {label:'Data optimization',value:'345'},
          ],
    addInfo:'Next payout in:  1h 23 min'
  }

  return model;
});

export const getPaymentInfo = createSelector(widgetData, (value) => {
  return value?.accountInfo?.balance;
});
