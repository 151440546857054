import * as types from './actionTypes';
import axios from 'axios';
import * as api from '../../api';
import catchRequestError from '../../commonExtensions/catchRequestError';
import { url as apiUrl } from '../../api'
import { Axiosinstance,checkAvailableHeader } from '../../api';


export function LoginInServer(id) {

	return dispatch => {
		return (

			axios.post(apiUrl + `/api/v1/accounts/signin`, id, api.headerData
			).then(function (response) {
				localStorage.setItem('token', response.data.accessToken);
				localStorage.setItem('refreshToken', response.data.refreshToken);
				localStorage.setItem('isLogin', 'true');
				if (id?.userName) {
					localStorage.setItem('userName', id.userName); // delete when whoami req will be ready
				}
				
				Axiosinstance.defaults.headers = checkAvailableHeader({ auth: true })
				//	dispatch(whoami()) // uncoment when whoami req will be ready

				return 'ok'

			})
				.catch((error) => {
					catchRequestError(error, dispatch);

				})
		
		)
	}
}

export function LogoutFromServer(id) {

	return dispatch => {
		return (
			console.log(api.headerData),
			Axiosinstance.post(apiUrl + `/api/v1/accounts/signout`, {}).then(function (response) {
				localStorage.setItem('userName', '');
				return 'ok'

			})
				.catch((error) => {
					catchRequestError(error, dispatch);

				})
		)
	}
}




export function RegistrInServer(id) {

	return dispatch => {
		return (

			axios.post(apiUrl + `/api/v1/accounts/signup`, id, api.headerData
			).then(function (response) {
				localStorage.setItem('token', response.data.accessToken);
				localStorage.setItem('refreshToken', response.data.refreshToken);
				localStorage.setItem('isLogin', 'true');
				localStorage.setItem('userName', id.userName); // delete when who am i will be ready
				Axiosinstance.defaults.headers = checkAvailableHeader({ auth: true })
				//	dispatch(whoami()) // uncoment when whoami req will be ready
				return 'ok'

			})
				.catch((error) => {
					catchRequestError(error, dispatch);

				})
		)
	}
}


export function getNewToken(key) {
	return dispatch => {
		return (
			Axiosinstance.get(`/api/v1/Accounts/token`).then(function (response) {
				return response.data.token

			})
				.catch((error) => {
					catchRequestError(error, dispatch);
				})
		)
	}
}


export function whoami(id) {

	return dispatch => {
		return (

			Axiosinstance.get(apiUrl + `/api/v1/whoami`).then(function (response) {
				
			 	localStorage.setItem('userName',response.data.userName);
				return 'ok'

			})
				.catch((error) => {
					catchRequestError(error, dispatch);

				})
		
		)
	}
}




