import { createSelector } from 'reselect';
import sumBy from 'lodash.sumby';

import { convertBytes } from '../../commonExtensions/bytesParser';
import { stringToLowerCase } from '../../commonExtensions/common';
import { timeParserUnixToDate } from '../../commonExtensions/timeParser';

import locale from '../../locale';

const files = state => state.files.files;

export const getFilesTableData = createSelector(files, (value) => {
  class FilesTableBody {
    constructor (data) {
      this.id = data.uuid;
      this.name = data.name;
      this.size = data.lengthBytes;
      this.state = stringToLowerCase(data.state);
      this.distribution = data.distribution;
      this.owner = data.owner;
      this.lastModified = data.createdTimestamp;
      this.details = '-';
      this.availability = '24/24 fragments';
      this.offlineState = data?.downloadProgress || 0;
    }

    get getSizeString() {
      return convertBytes(this.size);
    }

    get getStateString() {
      if (this.state === 'created') return locale('IDS_FILES__TABLE_STATE_CREATED_TITLE');
      if (this.state === 'scaling') return locale('IDS_FILES__TABLE_STATE_SCALING_TITLE');
      if (this.state === 'downloading') return locale('IDS_FILES__TABLE_STATE_DOWNLOADING_TITLE');
      if (this.state === 'degraded') return locale('IDS_FILES__TABLE_STATE_DEGRADED_TITLE');
      if (this.state === 'repairing') return locale('IDS_FILES__TABLE_STATE_REPAIRING_TITLE');
      if (this.state === 'ok') return locale('IDS_FILES__TABLE_STATE_OK_TITLE');

      return '';
    }

    get getDistribution() {
      return `${this.distribution} ${locale('IDS_FILES_FRAGMENTS_WIDGET_DISTRIBUTION_NODESONLINE_MSG')}`;
    }

    get getLastModified() {
      return timeParserUnixToDate({ unix: this.lastModified });
    }
  };

  return {
    header: [
      {
        column: 'name',
        sort: 'name',
        title: locale('IDS_FILES_TABLE_HEADER_NAME_TITLE'),
        style: { width: '50%' },
      },
      {
        column: 'getSizeString',
        sort: 'size',
        title: locale('IDS_FILES_TABLE_HEADER_SIZE_TITLE'),
      },
      {
        column: 'getLastModified',
        sort: 'lastModified',
        title: locale('IDS_FILES_TABLE_HEADER_LASTMODIFIED_TITLE'),
      },
      {
        column: 'offlineState',
        sort: 'offlineState',
        title: locale('IDS_FILES_TABLE_HEADER_OFFLINE_TITLE'),
      },
    ],
    body: Array.isArray(value) && value.map(item => new FilesTableBody(item)),
    config: {
      type: 'files',
      selectable: true,
    },
  };
});

const selectedTableRow = state => state.table.selectedRow;

export const getFilePropsWidgetData = createSelector(selectedTableRow, (value) => {
  return {
    size: value?.getSizeString || '',
    owner: value?.owner || '',
    lastModified: value?.getLastModified || '',
  }
});

export const getFileFragmentsWidgetData = createSelector(selectedTableRow, (value) => {
  return {
    state: value?.state || '',
    stateString: value?.getStateString || '',
    distribution: value?.getDistribution || '',
    details: value?.details || '',
    availability: value?.availability || '',
  }
});

export const getTotalFilesCount = createSelector(files, (value) => {
  return Array.isArray(value) ? value.length : 0;
});

export const getUsedFilesCapacity = createSelector(files, (value) => {
  return Array.isArray(value) ? convertBytes(sumBy(value, 'lengthBytes')) : '';
});

export const getFilesPopupWidgetData = createSelector(files, (value) => {
  class FilesPopupData {
    constructor (data) {
      this.id = data.uuid;
      this.name = data.name;
      this.state = stringToLowerCase(data.state);
      this.progress = data.fileUploadPercents;
      this.finishTime = '36 min ago';
    }

    get getState() {
      if (this.progress !== 100) return 'inprogress';

      return this.state;
    }
  }

  return {
    type: 'events',
    emptyMsg: locale('IDS_EVENTS_POPUP_WIDGET_EMPTY_MSG'),
    header: [
      { column: 'getState' },
      { column: 'name' },
      { column: 'finishTime' },
    ],
    body: Array.isArray(value) && value.map(item => new FilesPopupData(item)),
    counter:Array.isArray(value) && value.filter(item => item.fileUploadPercents !== 100).length,
  };
});

