// @flow

import React, { Component } from 'react';
import {
  connect
} from 'react-redux';

import ActionButton from '../../components/ActionButton';
import FilePropsWidget from '../../components/FilePropsWidget';
import FileFragmentsWidget from '../../components/FileFragmentsWidget';
import FilePlacementWidget from '../../components/FilePlacementWidget';
import DownloadFileWizard from '../../components/DownloadFileWizard';
import { convertBytes } from '../../commonExtensions/bytesParser';
import {
  getFilePropsWidgetData,
  getFileFragmentsWidgetData,
  getTableSelectedRow,
} from '../../store/selectors';
import {
  getFileInfo,
  postDownloadFile,
} from '../../store/actions';
import { Link } from "react-router-dom";

import locale from '../../locale';

import './styles.scss';
import { stringToLowerCase } from '../../commonExtensions/common';


class FilesDetails extends Component {
  constructor(props) {
    super(props)

    this.interval = null;

    this.state = {
      nodeStatus: {
        nodeCounts: 0,
        onlineNodes: 0,
        offlineNodes: 0,
        replacement: 0,
      },
      arrWithDots: [],
      downloadFileWizardVisible: false,
    }
  }


  componentWillUnmount() {
    clearInterval(this.interval)
  }




  componentDidMount() {

   

    let id = this.pageid()

    if (id) {
      this.setState({pageid:id})
      this.requestData(this.pageid());
      this.interval = setInterval(() => {
        this.requestData(this.pageid())
      }, 2000)
    }


  }

  pageid() {
   let id =  window.location.pathname;
     id = id.split('/')
    id = id[2]
    return id;
  }

  requestData(id) {
    this.props.dispatch(getFileInfo(id)).then(() => {
      
      this.filePropsWidgetGetInfo(this.props.fileInfo);
      this.fileFragmentsWidget(this.props.fileInfo);
      this.nodeCountWidgetData(this.props.fileInfo);
      this.mapData(this.props.fileInfo);
    })
  }


  mapData(info) {

    let nodes = info?.storageNodes || [];



    const covertState = (state) => {
      if (['ok'].find((item) => item == state)) {
        return 'available'
      }
      if (['finished', 'offline', 'failed'].find((item) => item == state)) {
        return 'not_available'
      }
      if (['checking', 'downloading', 'allocating', 'queued', 'downloading metadata', 'replacement'].find((item) => item == state)) {
        return 'replacement'
      }
      else {
        return 'not_available'
      }
    }

    let MappedArray = nodes.map((item) => {
      return ({
        'id': item.geolocation.countryNumericCode,
        'state': covertState(item.state.toLowerCase()),
        'coordinates': [item.geolocation.longitude, item.geolocation.latitude]
      });
    });

    this.setState({ arrWithDots: MappedArray })


  }

  nodeCountWidgetData(info) {

    let nodes = info?.storageNodes || [];

    this.setState({
      nodeStatus: {
        nodeCounts: nodes.length,
        onlineNodes: nodes.filter(item => item.state.toLowerCase() === 'ok').length,
        offlineNodes: nodes.filter
          (item => (item.state.toLowerCase() === 'finished'
            || item.state.toLowerCase() === 'offline'
            || item.state.toLowerCase() === 'failed')).length,
        replacement: nodes.filter
          (item => (item.state.toLowerCase() === 'queued'
            || item.state.toLowerCase() === 'checking'
            || item.state.toLowerCase() === 'downloading'
            || item.state.toLowerCase() === 'allocating'
            || item.state.toLowerCase() === 'replacement'
            || item.state.toLowerCase() === 'downloading metadata')).length,
      }
    })
  }

  fileFragmentsWidget(info) {

    function countnodes(nodes = [], desiredNodes) {
      let goodNodes = nodes.filter(item => item.state.toLowerCase() === 'ok')
      return `  ${goodNodes.length}/${desiredNodes} nodes online`
    }

    this.setState({
      fileFragmentsWidgetData: {
        state: info?.state.toLowerCase() || '',
        stateString: info?.state || '',
        distribution: countnodes(info?.storageNodes, info?.desiredNodes) || '',
        details: info?.details || '-',
        availability: `${info?.availability}` || '-',
      }

    })
  }

  filePropsWidgetGetInfo(info) {

    function timeConverter(UNIX_timestamp) {

      if (!UNIX_timestamp) {
        return ''
      }

      var a = new Date(UNIX_timestamp * 1000);
      var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      var year = a.getFullYear();
      var month = months[a.getMonth()];
      var date = a.getDate();
      var hour = a.getHours();
      var min = a.getMinutes();
      var sec = a.getSeconds();
      var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
      return time;
    }

    this.setState({
      filePropsWidgetData: {
        size: convertBytes(info?.lengthBytes) || '',
        owner: info?.owner || '',
        lastModified: timeConverter(info?.createdTimestamp) || ''
      }
    })
  }

  onOpenDownloadFileWizard = () => {
    this.setState({
      downloadFileWizardVisible: true,
    });
  }

  onCloseDownloadFileWizard = () => {
    this.setState({
      downloadFileWizardVisible: false,
    });
  }

  onConfirmDownloadFile = (value) => {
    const { dispatch } = this.props;

    dispatch(postDownloadFile({
      fileId: this.pageid(),
      data: {
        saveDirectory: value,
      },
    }));

    this.onCloseDownloadFileWizard();
  }


  render() {
    const {
      downloadFileWizardVisible,
    } = this.state;

    const downloadBtn = {
      type: 'download',
      caption: locale('IDS_FILES_DOWNLOADFILE_BTN_CAPTION'),
    };

    return (
      <div className='files_details__wrapper'>
        {downloadFileWizardVisible &&
          <DownloadFileWizard
            onConfirm={this.onConfirmDownloadFile}
            onClose={this.onCloseDownloadFileWizard}
          />}
        <div className='files_details__title_wrapper'>
          <h1 className="files_details__title  files_details__title--route"><Link to="/files">Files</Link></h1>
          <p className='files_details__title_route'>{this.props?.fileInfo?.name}</p>
        </div>

        <div className='files_details__btn_panel_wrapper'>
          <ActionButton
            data={downloadBtn}
            onClick={this.onOpenDownloadFileWizard}
          />
        </div>
        <div className='files_details__content_wrapper'>
          <div className='files_details__widget_panel_wrapper'>
            <div className='files_details__widget_wrapper'>
              <FilePropsWidget data={this.state.filePropsWidgetData} />
            </div>
            <div className='files_details__widget_wrapper'>
              <FileFragmentsWidget data={this.state.fileFragmentsWidgetData} />
            </div>
          </div>
          <div className='files_details__placement_wrapper'>
            <FilePlacementWidget arrWithDots={this.state.arrWithDots} nodeStatus={this.state.nodeStatus} />
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = function (dispatch) {

  return {

    dispatch: dispatch,

  }
}

function mapStateToProps(state) {


  return {

    fileInfo: state.files.file_info,

    // filePropsWidgetData: getFilePropsWidgetData(state),
    //  fileFragmentsWidgetData: getFileFragmentsWidgetData(state),

    //  const filePropsWidgetData = useSelector(getFilePropsWidgetData);
    // const fileFragmentsWidgetData = useSelector(getFileFragmentsWidgetData);


  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilesDetails);
