import { createSelector } from 'reselect';
import locale from '../../locale';


const peersList = state => state.peers.peersList;

export const getPeersListTableValue = createSelector(peersList, (value) => {
  class PeersTableBody {
    constructor (data) {
      this.id = data.workerUuid;
      this.ipAddress = data.ipAddress;
      this.port = data.port;
      this.state = data.state;
      this.workerUuid = data.workerUuid;
    }
  };

  return {
    header: [
      {
        column: 'ipAddress',
        sort: 'ipAddress',
        title: locale('IDS_PEERS_TABLE_HEADER_IPADDRESS_TITLE'),
        style: {
          width: '220px',
        }
      },
      {
        column: 'port',
        sort: 'port',
        title: locale('IDS_PEERS_TABLE_HEADER_PORT_TITLE'),
        style: {
          width: '80px',
        }
      },
      {
        column: 'state',
        sort: 'state',
        title: locale('IDS_PEERS_TABLE_HEADER_STATE_TITLE'),
        style: {
          width: '100px',
        }
      },
      {
        column: 'workerUuid',
        sort: 'workerUuid',
        title: locale('IDS_PEERS_TABLE_HEADER_WORKERUUID_TITLE'),
      },
    ],
    body: Array.isArray(value?.workers) && value.workers.map(item => new PeersTableBody(item)),
    config: {
      type: 'peers',
    },
  };
});

