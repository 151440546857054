// @flow

import React from 'react';

import './styles.scss';

const Billing = () => (
  <h2 className='billing__text'>Hello, this is Billing!</h2>
)

export default Billing;