// @flow

import React, { useState, useEffect } from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';

import TotalWidget from '../../components/TotalWidget';
import PieChartWidget from '../../components/PieChartWidget';

import {
  getTrustScore,
  getPayout,
  getStatusValue,
  getUsedSpaceValue,
  getPaymentInfo
  
} from '../../store/selectors';
import {
  getWidgetData,
  
} from '../../store/actions';


import locale from '../../locale';

import './styles.scss';

let getWidgetDataIntervalRequest = null;

const Status = ({status}) => {

  console.log(status)

  if (status === 'online') {
    return <div className="online-status" >Online</div>
  }
  if (status === 'degraded') {
    return <div className="degraded-status" >Degraded</div>
  }
  else {
    return <div className="offline-status" >Offline</div>
  }
 
  
}

const Dashboard = () => {
  const dispatch = useDispatch();
 
  const trustScore = useSelector(getTrustScore);
  const payout = useSelector(getPayout);
  const status = useSelector(getStatusValue);
  const space = useSelector(getUsedSpaceValue);
  const payment = useSelector(getPaymentInfo);


 // const [selectedChartPeriod, setSelectedChartPeriod] = useState(linechartPeriodOpt.find(item => item.value === chartPeriodValue));

  const containersData = {
    title: 'Net profit',
    value: '$ 2.78',
  };
  const totalBandwidthData = {
    title: locale('IDS_DASHBOARD_TOTAL_WIDGET_TOTALBANDWIDTH_TITLE'),
    value: '1.5 GB',
  }
  

 

  useEffect(() => {

    dispatch(getWidgetData());
    let getWidgetDataIntervalRequest = setInterval(() => dispatch(getWidgetData()), 30000);
    return () => {
      clearInterval(getWidgetDataIntervalRequest);
    } 
  }, []);

 

  return (
    <div className='dashboard__wrapper'>
      
      <div className='dashboard__widgets_separator'>
        
        <div className='dashboard__totalwidget_wrapper'>
          <TotalWidget
            data={{
              title: 'Status',
              field: <Status status={status?.statusEnum}/>,
              field_2:`Uptime ${status?.uptimePercents || 0}%`,
              field_3:`Up since:  ${status?.uptimeDays || 0}`
            }}
          />
        </div>
        <div className='dashboard__totalwidget_wrapper'>
          <TotalWidget
            data={space}
          />
        </div>
        <div className='dashboard__totalwidget_wrapper'>
          <TotalWidget
            data={{
              title: 'Current Balance',
              field: payment || '$ 0',
              field_2:'Current Balance',
              field_3:''
            }}
          />
          <button className="hide-numbers">Hide numbers</button>
        </div>
        
      </div>
      <div className='dashboard__widgets_separator'>
        <div className="dashboard_piewidget_wrapper">
          <PieChartWidget data={trustScore}/>
        </div>
        <div className="dashboard_piewidget_wrapper">
          <PieChartWidget data={payout} />
        </div>
      </div>
      
      
    </div>
  )
}

export default Dashboard;
