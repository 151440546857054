import axios from 'axios';
import * as api from '../../api';
import * as types from './actionTypes';
import catchRequestError from '../../commonExtensions/catchRequestError';
import { Axiosinstance } from '../../api';

export const setFiles = payload => ({
  type: types.SET_FILES,
  payload
});

export function getFiles() {
  return (dispatch) => {
    Axiosinstance.get(api.files)
      .then((response) => {
        if (response?.data) {
          dispatch(setFiles(response.data));
        }
      })
      .catch((error) => {
        catchRequestError(error, dispatch);
      })
  }
};


export const setFileInfo = payload => ({
  type: types.SET_FILES_INFO,
  payload
});


export function getFileInfo(id) {

	return dispatch => {
		return (
			//dispatch(showLoading()),
			Axiosinstance.get(api.files + `/${id}`).then(function (response) {
				dispatch(setFileInfo(response.data));
			})
				.catch((error) => {
          catchRequestError(error, dispatch);
				})
		)
	}
}

export const postFiles = (data) => (dispatch) => {
  Axiosinstance.post(api.files, data)
    .then((response) => {
      if (response?.data?.uuid) {
        dispatch(getFiles())
      }
    })
    .catch((error) => {
      catchRequestError(error, dispatch);
    })
};

export async function fileUploadChecker(fileId) {
  const data = await getFileUploadStatus(fileId);

  return data;
}

function getFileUploadStatus(fileId) {
  return new Promise(resolve => {
    let interval =  setInterval(() => {
      Axiosinstance.get(`${api.files}/${fileId}`)
      .then((response) => {
        if (response?.data?.progress === 100) {
          resolve({
            value: response.data,
            error: false,
          });
          clearInterval(interval);
        }
      })
      .catch((error) => {
        let errorInfo = new Error(error);

        resolve({
          msg: errorInfo?.message || '',
          statusCode: errorInfo?.name || '',
          error: true,
        });
        clearInterval(interval);
      })
    }, 5000);
  });
}

export const postDownloadFile = ({ fileId, data }) => (dispatch) => {
  Axiosinstance.post(`${api.files}/${fileId}/download`, data)
    .then((response) => {
    //  setInterval(()=> dispatch(checkFileDownloadStus(fileId)),2000);
    })
    .catch((error) => {
      catchRequestError(error, dispatch);
    })
};
export const checkFileDownloadStus = (fileId) => (dispatch) => {
  
  Axiosinstance.get(`${api.files}/${fileId}/offlinestate`)
    .then((response) => {

    })
    .catch((error) => {
      catchRequestError(error, dispatch);
    })
}



