import React, { memo, useState } from "react";
import {
    ZoomableGroup,
    ComposableMap,
    Geographies,
    Geography,
    Marker
} from "react-simple-maps";

import data from './world-110m.json';

const geoUrl = data;

const rounded = num => {
    if (num > 1000000000) {
        return Math.round(num / 100000000) / 10 + "Bn";
    } else if (num > 1000000) {
        return Math.round(num / 100000) / 10 + "M";
    } else {
        return Math.round(num / 100) / 10 + "K";
    }
};

const WorldMap = ({ setTooltipContent, arrWithDots }) => {

    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }

    const [position, setPosition] = useState({ coordinates: [20, 10], zoom: 1.2 });

    function handleZoomIn() {
        if (position.zoom >= 4) return;
        setPosition(pos => ({ ...pos, zoom: pos.zoom * 2 }));
    }

    function handleZoomOut() {
        if (position.zoom <= 1) return;
        setPosition(pos => ({ ...pos, zoom: pos.zoom / 2 }));
    }

    function handleMoveEnd(position) {
        setPosition(position);
    }

    function returnColor (state) {
        if (state === 'available') {
            return '#08C65F'
        }
        if (state === 'not_available') {
            return '#EF0606'
        }
        if (state === 'replacement') {
            return '#F3CF10'
        }
    }


    return (
        <>
            <ComposableMap width={1200} id="mapser" height={400} projectionConfig={{ scale: 220 }}>
                <ZoomableGroup

                    zoom={position.zoom}
                    center={position.coordinates}
                    onMoveEnd={handleMoveEnd}
                >
                    <Geographies geography={geoUrl}>
                        {({ geographies }) =>
                            geographies.map((geo,index) => {

                                let isHighlighted = arrWithDots.some(item => item.id == geo.id);
                                let showOrNot = isHighlighted;
                                isHighlighted = isHighlighted ? '#221733' : '#F7F3FF'
                                return (
                                    <Geography
                                        key={index || ''}
                                        geography={geo}
                                        // fill={isHighlighted ? '#221733' : '#EAE6F1'}
                                        onMouseEnter={() => {
                                            const { id } = geo;
                                            setTooltipContent(showOrNot ? `${id}` : null);
                                        }}
                                        onMouseLeave={() => {
                                            setTooltipContent("");
                                        }}
                                        style={{
                                            default: {
                                                fill: isHighlighted,
                                                outline: "none",
                                                stroke: isHighlighted
                                            },
                                            hover: {
                                                fill: isHighlighted,
                                                stroke: isHighlighted,
                                                outline: "none"
                                            },
                                            pressed: {
                                                fill: "#E42",
                                                outline: "none"
                                            }
                                        }}
                                    />)
                            })
                        }
                    </Geographies>
                    {arrWithDots.map(({ id, coordinates, state },index) => (
                        <Marker key={index} coordinates={coordinates}>
                            <circle r={2}
                                fill={returnColor(state)}
                               
                                stroke="#fff" strokeWidth={0} />

                        </Marker>
                    ))}
                </ZoomableGroup>
            </ComposableMap>
            <div className="controls">
                <button className="btn-plus" onClick={handleZoomIn}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                    >
                        <line x1="12" y1="5" x2="12" y2="19" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                    </svg>
                </button>
                <button className="btn-minus" onClick={handleZoomOut}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                    >
                        <line x1="5" y1="12" x2="19" y2="12" />
                    </svg>
                </button>
            </div>
        </>
    );
};

export default memo(WorldMap);
