import React, { useState, useEffect } from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import ReactDOM from "react-dom";

import locale from '../../locale';
import ReactTooltip from "react-tooltip";
import WorldMap from "../WorldMap";
import NodeCountWidget from "../NodeCountWidget"

import './styles.scss';



const FilePlacementWidget = ({nodeStatus,arrWithDots}) => {

  const [content, setContent] = useState("");

  const countryCodes = [
    {id:'804',name:'Ukraine'},
    {id:'840',name:'USA'},
    {id:'076',name:'Brasil'}
  ]

  // const nodeStatus = {
  //   nodeCounts:3,
  //   onlineNodes:1,
  //   offlineNodes:2,
  //   replacement:1,
  // }

  const tooltipView = (id) => {

    let nodeCount = arrWithDots.filter(item=> item.id == id)
    let countryName = countryCodes.find(item => item.id == id)

    return <div>
      <div><span className="tooltip-pad-1">Nodes:</span> {nodeCount.length}</div>
      <div><span className="tooltip-pad-1">Location:</span> <span className={'countryFlag countryFlag'+id}></span>  {countryName?.name}</div>
    </div>

  }

  return (
    <div className='file_placement__wrapper'>
      
      <div className="wt_header_wrapper">
            <div className="wt_header_wrapper_text">
            {locale('IDS_FILES_PLACEMENT_WIDGET_TITLE')}
            </div>
            </div>
      <div data-tip=""  className='file_placement__main_wrapper'>
        <NodeCountWidget data={nodeStatus}/>
    
        <WorldMap arrWithDots={arrWithDots} setTooltipContent={setContent} />
         
         <ReactTooltip arrowColor="transparent" className="tooltip-style">
           {content ? <div className="">{tooltipView(content)}</div> : null}
        </ReactTooltip>

      </div>
    </div>
  )
}

export default FilePlacementWidget;
